const r = () => "undefined" != typeof window,
  t = () => !(!r() || !window.hj),
  e = (t, ...e) => {
    if (r() && window.hj) return window.hj(t, ...e);
    throw Error("Hotjar is not available, make sure init has been called.");
  },
  n = (r, e, n) => {
    if (!((r, t, e) => {
      try {
        const n = document.getElementById(t) || document.createElement("script");
        return n.id = t, n.nonce = e, n.innerText = r, n.crossOrigin = "anonymous", document.head.appendChild(n), !0;
      } catch (r) {
        return !1;
      }
    })(`(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:${r},hjsv:${e},hjdebug:${(null == n ? void 0 : n.debug) || !1}};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`, "hotjar-init-script", null == n ? void 0 : n.nonce) || !t()) throw Error("Failed to initialize Hotjar tracking script.");
  },
  o = {
    init: (r, t, e) => {
      try {
        return n(r, t, e), !0;
      } catch (r) {
        return console.error("Error:", r), !1;
      }
    },
    event: r => {
      try {
        return e("event", r), !0;
      } catch (r) {
        return console.error("Error:", r), !1;
      }
    },
    identify: (r, t) => {
      try {
        return e("identify", r, t), !0;
      } catch (r) {
        return console.error("Error:", r), !1;
      }
    },
    stateChange: r => {
      try {
        return e("stateChange", r), !0;
      } catch (r) {
        return console.error("Error:", r), !1;
      }
    },
    isReady: t
  };
export default o;
